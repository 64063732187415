import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './CoreCompareFilter.module.css'

const CoreCompareFilter = ({ title, children, styleType }) => (
    <fieldset className={[styles.wrap, styles[styleType]].join(' ')}>
        <legend>{title}</legend>
        <div>{children}</div>
    </fieldset>
)

export default CoreCompareFilter

CoreCompareFilter.propTypes = {
    title: PropTypes.string.isRequired,
    styleType: PropTypes.string,
}
