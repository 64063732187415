import React, { Component } from 'react'
import { Link } from 'gatsby'
import queryString from 'query-string';
import styled from '@emotion/styled'
import Slider from '../components/page-components/find-core/Slider/Slider'
import TypeSelector from '../components/page-components/find-core/TypeSelector/TypeSelector'
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"
import CTA from '../components/CTA/CTA'
import Container from '../components/container'
import CoreCompareFilter from '../components/page-components/core-compare/CoreCompareFilters/components/CoreCompareFilter/CoreCompareFilter';

class FindCorePage extends Component {
    state = {
        type: 'RV32IMAC-MU',
        pipelineStages: 3,
        instructionCache: '4K',
    };

    render() {
        const { type, pipelineStages, instructionCache } = this.state
        const url = buildUrl({isa_identifier: [type], pipeline_stages: [pipelineStages], 'cache.instruction': [instructionCache]});
        console.log(url);
        return (
            <Layout>
                <SEO title="Company" />
                <Hero align="center">
                    <div style={{textAlign: 'center', maxWidth: '500px'}}>
                        <h2>Find Your Core</h2>
                        <p>Find cores with best functionality and performance for user applications.</p>
                    </div>
                </Hero>
                <Container>
                    <CoreCompareFilter title="Type" styleType="version">
                        <TypeIconContainer>
                            <TypeSelector onChange={val => this.setState({type: val})} />
                        </TypeIconContainer>
                    </CoreCompareFilter>
                    <CoreCompareFilter title="Pipeline Stages" styleType="pipeline">
                        <SliderContainer>
                            <Slider 
                                marks={[3, 4, 5, 6, 7, 8, 9, 10]} 
                                min={3} 
                                initial={3}
                                max={10} 
                                step={1} 
                                onChange={val => this.setState({pipelineStages: val})} />
                        </SliderContainer>
                    </CoreCompareFilter>
                    <CoreCompareFilter title="Instruction Cache" styleType="speed">
                        <SliderContainer>
                            <Slider 
                                theme="green"
                                marks={['0', '2K', '4K', '8K', '16K', '32K', '64K']} 
                                min={0} 
                                max={6} 
                                initial={2}
                                step={1} 
                                onChange={val => this.setState({instructionCache: val})} />
                        </SliderContainer>
                    </CoreCompareFilter>
                    <ActionsContainer>
                        <Link to="/compare-cores">View All Cores</Link>
                        <Link to={url}>View Results</Link>
                    </ActionsContainer>
                </Container>
                <CTA linkHref="/compare-cores" linkText="View All">
                    You can skip this step if you would like to browse all of our supported cores
                </CTA>
            </Layout>
        )
    }
  
}

export default FindCorePage

const buildUrl = (filters = {}) => {
    const query = {
        filters: encodeURIComponent(JSON.stringify(filters))
    };
    return `/compare-cores?${queryString.stringify(query)}`
}

const SliderContainer = styled.div`
    @media (min-width:769px) {
        width: 80%;
        margin: 4rem auto 2rem;
    }
`

const TypeIconContainer = styled.div`
    @media (min-width:769px) {
        width: 80%;
        margin: 4rem auto 2rem;
        display: flex;
        justify-content: space-around;
    }
`

const ActionsContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
`