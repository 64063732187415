import React from 'react'
import * as styles from './ActionLink.module.css'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ActionLink = ({ href, linkText, light, reverse }) => (
    <Link
        to={href}
        className={[
            styles.actionLink,
            styles.light,
            reverse && styles.reverse,
        ].join(' ')}
    >
        {linkText}
    </Link>
)

export default ActionLink

ActionLink.propTypes = {
    href: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
}
