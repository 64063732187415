import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './CTA.module.css'
import ActionLink from '../ActionLink/ActionLink'
import Button from '../Button/Button'

const CTA = ({ linkHref, linkText, children, margin, container, button }) => (
    <section className={[styles.wrap, margin ? styles.margin : ''].join(' ')}>
        <div className={[container ? styles.container : '']}>
            <span>{children}</span>
            <div className={styles.linkBtnWrap}>
                {button ? (
                    <Button to={linkHref}>{linkText}</Button>
                ) : (
                    <ActionLink href={linkHref} linkText={linkText} light />
                )}
            </div>
        </div>
    </section>
)

CTA.propTypes = {
    linkHref: PropTypes.string,
    linkText: PropTypes.string,
    margin: PropTypes.bool,
    container: PropTypes.bool,
}

export default CTA
