import React, { Component } from 'react'
import PropTypes from 'prop-types'

import * as styles from './Slider.module.css'

class Slider extends Component {
    state = {
        inputVal: '',
    }

    componentDidMount() {
        const { marks, step, min, initial } = this.props
        if (initial) {
            this.setState({ inputVal: initial })
        } else {
            const middleVal = Math.round((marks.length - 1) / 2) * step + min
            this.setState({ inputVal: middleVal })
        }
    }

    handleSliderInput(e) {
        const value = e.target.value
        const { min, marks } = this.props
        this.setState({ inputVal: value })
        this.props.onChange(marks[value - min])
    }

    render() {
        const { inputVal } = this.state
        const { step, max, min, marks, theme } = this.props
        return (
            <div
                className={[
                    styles.slidecontainer,
                    styles[`theme_${theme}`],
                ].join(' ')}
            >
                <input
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    value={inputVal}
                    className={styles.slider}
                    onChange={val => this.handleSliderInput(val)}
                />
                <ul className={styles.marks}>
                    {marks.map((mark, index) => (
                        <li key={index}>{mark}</li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default Slider

Slider.propTypes = {
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    marks: PropTypes.array,
    theme: PropTypes.oneOf(['blue', 'green', 'orange']),
}

Slider.defaultProps = {
    step: 10,
    min: 0,
    max: 100,
    marks: [],
    theme: 'blue',
}
