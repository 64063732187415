import React, { Component } from 'react'
import * as styles from './TypeSelector.module.css'

class TypeSelector extends Component {
    state = {
        selectedOption: 'RV32IMAC-MU',
    }

    handleOptionChange(e) {
        const value = e.target.value
        this.setState({ selectedOption: value })
        this.props.onChange(value)
    }

    render() {
        const { selectedOption } = this.state
        return (
            <div className={styles.wrap}>
                <input
                    type="radio"
                    name="radio"
                    id="radio-1"
                    className={styles.radio}
                    value="RV32IMAC-MU"
                    checked={selectedOption === 'RV32IMAC-MU'}
                    onChange={this.handleOptionChange.bind(this)}
                />
                <label htmlFor="radio-1">RV32I</label>
                <input
                    type="radio"
                    name="radio"
                    id="radio-2"
                    className={styles.radio}
                    value="RV64GC-MUS"
                    checked={selectedOption === 'RV64GC-MUS'}
                    onChange={this.handleOptionChange.bind(this)}
                />
                <label htmlFor="radio-2">RV64I</label>
            </div>
        )
    }
}

export default TypeSelector
